.app {
  text-align: center;
  font-family: 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: rgb(225, 225, 225);
  
}

.header {
  font-size: 1.2em;
  font-weight: bold;
  padding: 20px;
  background-color: #e8e8e8; /* Softer color */
  border-bottom: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Consistent shadow */
}

.messages-container {
  display: flex;
  flex-direction: column;
  padding: 12px;
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  margin: 12px auto;
  text-align: left; /* Default text alignment for all messages */
  border-radius: 15px;
  width: 90%;
  max-width: 1000px;
  margin-bottom: 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

.message {
  margin: 10px 0;
  padding: 12px;
  border-radius: 36px;
  max-width: 70%;
  width: fit-content;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  line-height: 1.5;
  font-size: 16px;
  background-color: #5090ff;
  box-sizing: border-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

.message p {
  margin: 0;
  padding: 0;
}
/* Apply left alignment styles for User messages */
.message.User {
  align-self: flex-end; /* Aligns message boxes to the left */
  text-align: left; /* Text within the message box */
  background-color: #419ED6;
  color: white;
}

/* Apply right alignment styles for Assistant messages */
.message.Assistant {
  align-self: flex-start; /* Aligns message boxes to the right */
  text-align: left; /* Text within the message box */
  background-color: #027BC6;
  color: white;
}

.message.Assistant a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  text-decoration: underline;
  transition: color 0.1s ease, background-color 0.1s ease;
  padding: 2px 6px;
}

.message.Assistant a:hover {
  color: #0056b3;
  text-decoration: underline;
}




.search-container {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 1200px; /* Adjust as necessary */
  max-width: 85%;
}

.search-input {
  width: 100%; /* Take full width of search container */
  padding: 20px 20px;
  border: none;
  box-sizing: border-box;
  border-radius: 24px; /* Large border radius for pill shape */
  box-shadow: 0 12px 12px rgba(0, 0, 0, 0.3); /* Subtle shadow */
  outline: none;
  font-size: 16px; /* Adjust as necessary */
}

.search-button {
  display: none; /* Hide button if using input only design */
}

.h2 {
  background-color: #5F5F5F; /* Darker shade for the header */
  color: white; /* White text for contrast */
}

.message table {
  width: 100%; /* Full-width tables */
  border-collapse: separate; /* Separate borders to allow border-radius */
  border-spacing: 0; /* Remove default border spacing */
  margin: 20px 0; /* Margin for spacing */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: adds a slight shadow for depth */
  border-radius: 12px; /* Rounded corners */
  overflow: hidden; /* Ensure the contents don't spill out the border-radius */
}

/* Table Header */
.message th {
  background-color: #f4f4f4; /* Light grey background */
  color: #333; /* Dark text for contrast */
  font-weight: bold; /* Make header fonts bold */
  text-align: left; /* Align text to the left */
  padding: 12px 15px; /* Padding for spacing */
}

/* Table Body */
.message td {
  padding: 10px 15px; /* Padding for spacing */
  border-bottom: 1px solid #ddd;
   /* Border for each row */
}

/* Optional: Responsive Table */
@media screen and (max-width: 768px) {
  .message table {
    display: block;
    overflow-x: auto; /* Scroll on small screens */
  }
}

/* Bubble Container */
.bubble-container {
  position: absolute;
  bottom: 64px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center; /* Center bubbles horizontally */
  gap: 12px; /* Space between bubbles */
  z-index: 10;
  max-width: 100%; /* Maximum width of the container */
  background-color: transparent;
}

.bubble {
  background-color: #242F40;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.bubble-text {
  font-size: 14px;
  color: white;
  white-space: nowrap;
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.0);
  }
}

.dot {
  height: 8px;
  width: 8px;
  margin: 0 2px;
  background-color: #000000;
  border-radius: 50%;
  display: inline-block;
  animation: bounce 1.4s infinite ease-in-out both;
}

.dot1 {
  animation-delay: -0.32s;
}

.dot2 {
  animation-delay: -0.16s;
}

/* Hide scrollbar for Chrome, Safari and Edge */
::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* Hide scrollbar for Firefox */
* {
  scrollbar-width: thin; /* or 'none' if you want to completely remove it */
  scrollbar-color: transparent transparent; /* thumb and track color */
}

::-webkit-scrollbar {
  width: 12px; /* Adjust as needed but not 0px */
}

::-webkit-scrollbar-track {
  background: transparent; /* Invisible track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Visible thumb, choose a color that stands out */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover for better visibility */
}

.thumbs-up-icon,
.thumbs-down-icon,
.copy-icon,
.checkmark-icon {
  width: 18px;
  height: 18px;
  fill: none; /* Assuming the SVG can take a fill color */
  cursor: pointer;
  margin-left: 10px; /* Add some space between the message and the icon */
  padding: 10px; /* Add padding to make the background larger */
  border-radius: 15px; /* Add a rounded background */
  transition: background-color 0.3s ease, transform 0.3s ease, fill 0.3s ease, stroke 0.3s ease; /* Gradual color change */
}

.thumbs-up-icon.clicked,
.thumbs-down-icon.clicked {
  transform: scale(1.2); /* Slightly enlarge the icon when clicked */
}

.thumbs-up-icon.clicked .thumbs-up-path,
.thumbs-down-icon.clicked .thumbs-down-path {
  stroke: rgb(137, 136, 136); /* Change stroke color to black when clicked */
  fill: white; /* Change fill color to white when clicked */
}

.thumbs-down-icon.clicked {
  transform: rotate(180deg); 
}

.thumbs-up-icon .thumbs-up-path,
.thumbs-down-icon .thumbs-down-path {
  stroke: currentColor; /* Set the stroke color to current text color */
  stroke-width: 2px;
}

.copy-icon .copy-path {
  stroke: currentColor; /* Set the stroke color to current text color */
  stroke-width: 2px;
}

.checkmark-icon .checkmark-path {
  stroke: rgb(137, 136, 136); /* Set the stroke color to white */
  fill: currentColor;
  stroke-width: 1px;
}

.thumbs-down-icon {
  transform: rotate(180deg); /* Rotate the thumbs-down icon */
}

.thumbs-up-icon:hover,
.thumbs-down-icon:hover,
.copy-icon:hover,
.checkmark-icon:hover {
  background-color: rgba(224, 224, 224); /* Change background color on hover with 30% opacity */
  border-radius: 15px; /* Add a rounded background */
  transform: scale(1.2); /* Slightly enlarge the icon on hover */
}

.thumbs-down-icon:hover {
  transform: rotate(180deg) scale(1.2); /* Maintain rotation and scale on hover */
}

.icon-container {
  display: flex;
  justify-content: flex-end; /* Ensure icons are aligned to the right */
  align-items: center; /* Center the icons vertically */
}

.transition-icon {
  animation: fade 0.5s forwards; /* Gradual transition over 1 second */
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.copy-container {
  position: relative;
  display: inline-block;
}

.copy-container .tooltip-text {
  visibility: hidden;
  width: 100px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the icon */
  left: 50%;
  margin-left: -50px;
  opacity: 0;
  transition: opacity 0.3s;
}

.copy-container .tooltip-text::after {
  content: '';
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.copy-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}






